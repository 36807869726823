
import { BrowserRouter, Routes, Route } from 'react-router-dom';


// screens
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Hire from './pages/Hire';
import PageNoteFound from './pages/PageNoteFound';




function App() {

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route  path='/about' element={<About />} />
        <Route  path='/hire' element={<Hire />} />
        <Route  path='/contact' element={<Contact />} />
        <Route  path='/services' element={<Services />} />


        <Route  path='*' element={<PageNoteFound />} />

      </Routes>
    </BrowserRouter>
      

    </>
  );
}

export default App;
