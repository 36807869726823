import {Link } from "react-router-dom";

export default function Foot (){
    return(
        <>
           
           
<footer>
    <div className='footerContainer'>
            <div className='footerContainer_Sub1'>
                <div className='footerContainer_Sub1_Card'>
                    <h2>Humble Web Tech</h2>
                    <p>
                    Humble Web Tech is a reputable and one of the top web design & development, search engine optimization and digital marketing agency .

                    </p>
                    <p>
                    Our ultimate objective is to provide IT services and solutions that helps in simpifying and growing our client's business.


                    </p>
                </div>
                <div className='footerContainer_Sub1_Card'>
                    <h2>Links</h2>
                    <ul>
                                <li><Link to='/'>Home</Link></li>
                                <li><Link to='/about'>About</Link></li>
                                <li><Link to='/services'>Services</Link></li>
                               
                                <li><Link to='/hire'>Hire Us</Link></li>
                                <li><Link to='/contact'>Contact</Link></li>
                    </ul>
                </div>
                <div className='footerContainer_Sub1_Card'>
                    <h2>Office Address</h2>
                    <p>
                    Wing B, suite B8, First Floor, 2XL Mall, 3rd Avenue, Christiana Ajayi Okunaga, Gwarinpa Estate 900108, Federal Capital Territory.
                    </p>

                    {/* <h2>Phone Number</h2> */}
                    <p>
                    <a href="tel:+2349054020833" style={{color: 'white', textDecoration: "none",}}>+2349054020833 </a>
                    </p>

                    {/* <h2>Email</h2> */}
                    <p>
                       <a href="mailto:humblewebtech@gmail.com" style={{color: 'white', textDecoration: "none",}}>  humblewebtech@gmail.com </a>
                    </p>
                </div>

                <p className="clearFloat"></p>

                <div className='copyWriteFooter'>
                    <p>
                        Copyright © 2024 Humble Web Tech, All rights reserved.
                    </p>
                </div>
            </div>




    </div>
</footer>

        
        
        
        
        
        </>
    );
}