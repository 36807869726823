
// import {useEffect, useState } from 'react';
import {Link, useNavigate } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
// pages
import Head from '../pages/Head';
import Foot from '../pages/Foot';
import bg3 from "../bg3.jpg";
import bg4 from "../cert.jpg";

import Swal from 'sweetalert2';
import $ from 'jquery';
import {useState} from 'react';
import axios from 'axios';

import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';

export default function Home (){

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [messageFromUser, setMessageFromUser] = useState("");

    const [errMsg, setErrMsg] = useState("");




    const submitContactForm = (e) => {
        e.preventDefault();

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if(fullName == "" || email == "" || messageFromUser == "" ){

            // setErrMsg("Provide all details");

            Swal.fire({
                title: "Error!",
                text: "Provide all necessary details.",
                icon: "error",
                // toast:true,
                color: "#fff",
                background: "#010c4c",
                // timerProgressBar: true,
                allowOutsideClick: false,
                showDenyButton:false,
                // showConfirmButton: false,
                confirmButtonText: "ok!",
                confirmButtonColor: "#020f60",
                
              });

              return false;


        }else if(!email.match(validRegex)){

            // setErrMsg("Provide a valid email address");
            Swal.fire({
                title: "Error!",
                text: "Provide a valid email address.",
                icon: "error",
                // toast:true,
                color: "#fff",
                background: "#010c4c",
                // timerProgressBar: true,
                allowOutsideClick: false,
                showDenyButton:false,
                // showConfirmButton: false,
                confirmButtonText: "ok!",
                confirmButtonColor: "#020f60",
                
              });
            return false;


        }else{

            setErrMsg("");
            $("#ShowCase2formButton1").show();
            $("#ShowCase2formButton").hide();

            let urls = "https://backend.humblewebtech.com/api/contactpost";
            // let urls = "http://127.0.0.1:8000/api/contactpost";

            async function runProcess() {
            try {
                
                const res =   await axios.post(urls, {
                    fullName: fullName,
                    email: email,
                    messageFromUser: messageFromUser,
                  });
            
                //   console.log(res.data.msg);

                let statusMsgFromServer = res.data.msg;
                let statusCodeFromServer = res.data.status;

                if(statusCodeFromServer == 200)
                {
                    // alert(statusMsgFromServer);

                    Swal.fire({
                        title: "Sent",
                        text: statusMsgFromServer,
                        icon: "success",
                        // toast:true,
                        color: "#fff",
                        background: "#010c4c",
                        // timerProgressBar: true,
                        allowOutsideClick: false,
                        showDenyButton:false,
                        // showConfirmButton: false,
                        confirmButtonText: "ok!",
                        confirmButtonColor: "#020f60",
                        
                      });


                      $("#ShowCase2formButton1").hide();
                      $("#ShowCase2formButton").show();
                    
                      setFullName("");
                      setEmail("");
                      setMessageFromUser("");


                    // navigate("/");
                }else if(statusCodeFromServer == 501){

                    alert(statusMsgFromServer);

                }else{


                }



              
            } catch (error) {

                setErrMsg("Error sending mail");
                // console.log(error);
            }

          }
          
          runProcess();







        }




        


        // async function runProcess() {
        //     const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
        //     const json = await response.json();
        //     console.log(json)
        //   }
          
        //   runProcess();
        


        // async function runProcess() {
        //     try {
        //       const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
        //       const json = await response.json();
        //       console.log(json);
        //     } catch (error) {
        //       console.log("error trying to fetch data");
        //     }
        //   }
          
        //   runProcess();



    }






















    const navigate = useNavigate();


    // submit handler
    const subbmitHanler = (e) => {
        e.preventDefault();
    }




    return(
        <>

        
    

            <Head />



        <div className='showcaseContainer'>
            <div className='subContainer'>

                {/* subContainerShowCase1 */}
                <div className='subContainerShowCase1'>
                    <div className='subContainerShowCase1_Content'>
                        <h2 className='showCaseHeading animate__animated animate__bounce'>Humble Web Tech   </h2>
                        <p className='showCasePara animate__animated animate__pulse'>
                            Humble Web Tech is a reputable and one of the top web design and mobile app development company. 
                            <br />
                            We provide professional services on website design and mobile app development.
                        </p>

                        <div className='showCaseContainerForButton animate__animated animate__backInLeft'>
                            <Link to='/about'>
                                <div className='showCaseContainerForButton1'>
                                    <Link to='/about' className='aboutButtonShowCase1'>About Us</Link>
                                </div>
                            </Link>

                            <Link to='/hire'>
                                <div className='showCaseContainerForButton2'>
                                    <Link to='/hire' className='aboutButtonShowCase2'>Request Quote</Link>
                                </div>
                            </Link>

                            <p className="clearFloat"></p>
                        </div>


                       





                    </div>

                    
                </div>

                {/* subContainerShowCase2 */}
                <div className='subContainerShowCase2'>
                    
                    <div className="subContainerAnimation">
                         <img src={bg3} alt="HumbleWeb Tech Logo" style={{width:"100%"}}/>
           
                    </div>
                

                </div>

                <p className="clearFloat"></p>
            </div>
        
        </div>


        {/* section 1 */}

        <section className='sectioneOne'>

                <br />
                <br />
                <br />
                <br />
    
                
            <div className='sectioneOneContainer'>
                <div className='subSectioneOneContainer'>


               
                    <h2 className='subSectioneOneContainer_Heading'  >About Us</h2>
                    


                    <ScrollAnimation animateIn="animate__wobble">
                    <p >
                    Humble Web Tech is a reputable and one of the top web design and mobile app development company. We provide professional services on website design and mobile app development.

                    </p>
                   
                   <p>
                   We don’t just build basic websites, your site is expertly designed and marketed by professionals who are wizards when it comes to turning users into raving fanatics and generating consistent leads for your business.

                   </p>
                   
                    <p>
                    We are one of the biggest and fastest growing web design and digital maketing agency with over four years of working experience. We specialise in building businesses and brands digitally across the world.


                    </p>

                    <p>
                    Finding a web designer who specialises in everything you need them to can be very difficult, and that is where we come in. We offer the best services.


                    </p>

                    <p>
                    Our services and products are backed by latest technology, years of cumulative experience. Our ultimate objective is to provide IT services and solutions that helps in simpifying and growing our client's business.


                    </p>

                   
                    <p>
                    Our deep understanding of  Web design and development, mobile app development and SEO, which is reflected in every website we build is one of the reasons why we stand out. We invest time and money continuously developing our skills in these areas, which means your website is the best it can possibly be.


                    </p>
                    </ScrollAnimation>
                   

                    <br />
                    <center>
                       <img src={bg4} alt="HumbleWeb Tech Logo" style={{width:"60%"}}/>
 
                    </center>
                    
                   <br />
                   {/* <Link to="/hire" className='hireUsButton'>Hire Us</Link>/ */}

<br />
<br />
<br />
<br />
                </div>

            </div>


            
        </section>




        {/* section 2 */}

        <section className='sectionTwo'>
                <div className='subsectionTwo'>
                <ScrollAnimation animateIn="animate__flash">
                    <h2>Our Services</h2>
                </ScrollAnimation>
                    <div className='ourSericesContent'>
                        {/* card start */}
                        <div className='ourSericesContent_Card'>

                            <div className='ourSericesContent_Card_Content'>
                                <div className='ourSericesContent_Card_Content_Images_1'></div>

                                <h3>Web Design & Development</h3>

                                <ScrollAnimation animateIn="animate__bounceIn">
                                <p>
                                A website is the heart of your business. It’s that little space on the internet that represents who you are and what you stand for. We design standard and responsive websites.

                                </p>
                                </ScrollAnimation>
                            </div>

                        </div>

                         {/* card start */}
                         <div className='ourSericesContent_Card'>

                            <div className='ourSericesContent_Card_Content'>
                                <div className='ourSericesContent_Card_Content_Images_3'></div>

                                <h3>Mobile App Development</h3>

                                <ScrollAnimation animateIn="animate__bounceIn">
                                <p>
                                        At Humble Web Tech we develop mobile applications for both android and IOS. Our goal is to make sure that users are adopting and benefiting from all its features.

                                </p>
                                </ScrollAnimation>
                            </div>


                        </div>


                        {/* card start */}
                        <div className='ourSericesContent_Card'>

                            <div className='ourSericesContent_Card_Content'>
                                <div className='ourSericesContent_Card_Content_Images_2'></div>

                                <h3>SEO & Digital Marketing</h3>

                                <ScrollAnimation animateIn="animate__bounceIn">
                                <p>
                                Our mission is to grow your online reputation, drive quality organic traffic to your website, generating and increasing your sales & build awareness for your brand.

                                </p>
                                </ScrollAnimation>
                            </div>

                        </div>

                       

                        {/* card start */}
                        <div className='ourSericesContent_Card'>

                            <div className='ourSericesContent_Card_Content'>
                                <div className='ourSericesContent_Card_Content_Images_4'></div>

                                <h3>Content Marketing</h3>

                                <ScrollAnimation animateIn="animate__bounceIn">
                                <p>
                                We drive profitable customer with our strategic marketing approach focused on creating and distributing your content to attract, acquire, and engage a defined target audience.

                                </p>
                                </ScrollAnimation>
                            </div>


                        </div>


                        <p className="clearFloat"></p>
                    </div>


                </div>

        </section>



{/* section three */}

<section className='sectionThree' >
        <div className='sectionThree_Container'>

        <div className='subContainer' >

{/* subContainerShowCase1 */}
<div className='subContainerShowCase1' >
    
    <div className='subContainerShowCase1_Content'>
        <h2 className='showCaseHeading' style={{fontSize:25}}>Why Choose Humble Web Tech!</h2>
        <p className='showCasePara'>
        Not every website is created equal and finding a web designer who specialises in everything you need them to can be very difficult. Building a website is easy, building an effective website that will help your business foster a pipeline of new leads and customers requires a special kind of talent and skills.
        </p>
        <p className='showCasePara'>
        Your business needs professionals that have the skills, technology, and experience to create an industry leading website for your business that can compete in today’s overcrowded marketplace.
        </p>
        <p className='showCasePara'>
        We offer the best services at Humble Web Tech, Web design & development, mobile app development, digital marketing, and Search engine optimization.
        </p>

        <div className='showCaseContainerForButton'>
            <Link to='/hire'>
                <div className='showCaseContainerForButton1'>
                    <Link to='/hire' className='aboutButtonShowCase1'>Hire Us</Link>
                </div>
            </Link>
            {/* <div className='showCaseContainerForButton2'>
                <a className='aboutButtonShowCase2'>Request Quote</a>
            </div> */}
        </div>
    </div>

    
</div>

{/* subContainerShowCase2 */}
<div className='subContainerShowCase2' >
    <div className='subContainerShowCase2_Content' style={{boxShadow:'0px 0px 0px white', border:'0px solid white', backgroundColor: "white"}}>
        {/* <h2 className='subContainerShowCase2_Content_Heading'>logo here</h2> */}
        <div className="sectionThree_Container_2_Img" >
            {/* <img src={bg4} alt="HumbleWeb Tech Logo" style={{width:"100%"}}/> */}
            
        </div>
            

    </div>





</div>

<p className="clearFloat"></p>
</div>



        </div>



</section>


{/* section four */}

<section className='sectionFour'>
    <div className='sectionFour_Content'>
                <h2>How We Work</h2>
            <div className='sectionFour_Content_Card'>
                <div className='sectionFour_Content_Card_Sub'>
                    <div className='sectionFour_Content_Card_Sub1'>
                        <img src="img/dis.svg" alt="discovery image" style={{width: 70, height: 70,}} />
                    </div>

                    <div className='sectionFour_Content_Card_Sub2'>
                        <h4>Discovery</h4>
                        <p>
                        Clarify the what and why to bring your vision into focus.
                        </p>
                    </div>

                    <p className="clearFloat"></p>
                </div>
            </div>
            <div className='sectionFour_Content_Card'>
                <div className='sectionFour_Content_Card_Sub'>
                    <div className='sectionFour_Content_Card_Sub1'>
                    <img src="img/plan.svg" alt="planning image" style={{width: 70, height: 70,}} />
                    </div>

                    <div className='sectionFour_Content_Card_Sub2'>
                        <h4>Planning</h4>
                        <p>
                        Define the roadmap that aligns us on the journey ahead.
                        </p>
                    </div>

                    <p className="clearFloat"></p>
                </div>
            </div>
            <div className='sectionFour_Content_Card'>
                <div className='sectionFour_Content_Card_Sub'>
                    <div className='sectionFour_Content_Card_Sub1'>
                    <img src="img/design.svg" alt="design image" style={{width: 70, height: 70,}} />
                    </div>

                    <div className='sectionFour_Content_Card_Sub2'>
                        <h4>Design</h4>
                        <p>
                        We design accordingly to the plan into digital.
                        </p>
                    </div>

                    <p className="clearFloat"></p>
                </div>
            </div>


            <div className='sectionFour_Content_Card'>
                <div className='sectionFour_Content_Card_Sub'>
                    <div className='sectionFour_Content_Card_Sub1'>
                    <img src="img/dev.svg" alt="development image" style={{width: 70, height: 70,}} />
                    </div>

                    <div className='sectionFour_Content_Card_Sub2'>
                        <h4>Development</h4>
                        <p>
                        Shape function, try ruthlessly and leave nothing to chance.
                        </p>
                    </div>

                    <p className="clearFloat"></p>
                </div>
            </div>


            <div className='sectionFour_Content_Card'>
                <div className='sectionFour_Content_Card_Sub'>
                    <div className='sectionFour_Content_Card_Sub1'>
                    <img src="img/support.svg" alt="support image" style={{width: 70, height: 70,}} />
                    </div>

                    <div className='sectionFour_Content_Card_Sub2'>
                        <h4>Support</h4>
                        <p>
                        Count on us to continue to actively invest in your long-term success.
                        </p>
                    </div>

                    <p className="clearFloat"></p>
                </div>
            </div>

            <p className="clearFloat"></p>


            {/* hire us form */}

            <div className='hireUsFormContainer'>
                    <h2>Hire Us</h2>


                    <div className='hireUsFormContainer_Intro'>
                        <h3>HERE’S WHAT TO EXPECT:</h3>
                        <ul>
                            <li> An immediate email confirming your request. </li>
                            <li> A phone call from one of the strategists at Humble Web Tech. </li>
                            <li> We will work together to clarify your requirements. </li>
                            <li> We will share ideas on how to help you achieve your goals. </li>
                            <li> Whenever you’re ready to begin, we’ll send you a custom quote or proposal. </li>
                        </ul>
                    </div>

                    <br />
                    <center>
                       

                        <button className='HireShowCase2formButton' onClick={() => navigate("/hire")}>Hire Us</button>
                        
                    </center>
                    

                   

            </div>
    </div>


</section>





{/* footer */}


<Foot  />






        

        
  
        </>
    );
}