import {Link } from "react-router-dom";
import { useEffect } from "react";
import logo from "../logo.png";
import barx from "../barx.png";
import barxx from "../barxx.png";


import $ from 'jquery';

export default function Head (){

    
    useEffect(() => {
        window.scrollTo(0, 0);

      }, []);


      

    return(
        <>
        <header>
            <div className="headBackgroundContainer">

                <div className="subContainerHeader">
                    <div className="subContainerHeader1">
                        {/* <h2 className="logoContainer">Logo</h2> */}

                        
                        <Link to='/'>
                        {/* <img src="logo.png" alt="Humble Web Tech Logo" className="logoImg" /> */}
                        <img src={logo} alt="Humble Web Tech Logo" className="logoImg" />
                        </Link>
                       
                    </div>

                    <div className="subContainerHeader2">
                        <div className="navContainer">
                            <ul>
                                <li><Link to="/">Home</Link> </li>
                                <li> <Link to="/about">About</Link> </li>
                                <li><Link to="/services">Services</Link> </li>
                                {/* <li>Faq</li> */}
                                <li><Link to="/hire">Hire Us</Link> </li>
                                <li><Link to="/contact">Contact</Link> </li>
                            </ul>
                            
                        </div>


                    </div>

                    <div className="barx" onClick={showSideBar} id="barx">
                        <img src={barx} alt="" width={30} height={20} />
                    </div>

                    <div className="barxx" onClick={closeSideBar} id="barxx">
                        <img src={barxx} alt="" width={30} height={20} />
                    </div>

                    <div className="sideBarContainer" id="sideBarContainer">
                        <div className="sideBarContainer_Sub">

                            <ul>
                                <li><Link to="/">Home</Link> </li>
                                <li> <Link to="/about">About</Link> </li>
                                <li><Link to="/services">Services</Link> </li>
                                {/* <li>Faq</li> */}
                                <li><Link to="/hire">Hire Us</Link> </li>
                                <li><Link to="/contact">Contact</Link> </li>
                            </ul>
                            

                        </div>
                    </div>
                    <p className="clearFloat"></p>


                </div>
                
                


            </div>
        
            </header>
        
        
        
        </>
    );
}



// const styles = {
//     color: "red"
// }


function showSideBar (){
    
    $("#sideBarContainer").fadeIn(1000);
    $("#barxx").show();
    $("#barx").hide();

}

function closeSideBar (){
    
    $("#sideBarContainer").fadeOut(1000);
    $("#barx").show();
    $("#barxx").hide();

}