import {useState} from 'react';
import $ from 'jquery';
import Swal from 'sweetalert2';
import axios from 'axios';

import Head from '../pages/Head';
import Foot from '../pages/Foot';


export default function Hire(){

    const [dicussIdeal, setdicussIdeal] = useState("");
    const [services, setservices] = useState("");
    const [sample, setsample] = useState("");
    const [startproject, setstartproject] = useState("");
    const [budget, setbudget] = useState("");
    const [fullName, setfullName] = useState("");
    const [email, setemail] = useState("");
    const [besWayToReach, setbesWayToReach] = useState("");
    const [besWayToReachDetails, setbesWayToReachDetails] = useState("");
    

    // update bestwaydetailstoreach function
    // (e) => setbesWayToReach(e.target.value)
    const updateBestWayDetails = (e) => {
        setbesWayToReach(e.target.value);

        let valx = $("#besWayToReach").val();

        
        if(valx == "Whatsapp")
        {

            $("#chnx").text("Enter Your Whatsapp Number (start with your country code. e.g +1...).");
            $("#ShowCase2formLabelContainerx").show(1000);

        }else if(valx == "Email"){

            $("#chnx").text("Enter your valid email address.");
            $("#ShowCase2formLabelContainerx").show(1000);
        
        }else if(valx == "Telegram"){

            $("#chnx").text("Enter your valid telegram username.");
            $("#ShowCase2formLabelContainerx").show(1000);
        
        }else if(valx == "Instagram"){

            $("#chnx").text("Enter your valid instagram username.");
            $("#ShowCase2formLabelContainerx").show(1000);
        
        }else if(valx == "Linkedln"){

            $("#chnx").text("Enter your valid Linkedln username.");
            $("#ShowCase2formLabelContainerx").show(1000);




        }else{

















        }


     
           
    }

   


    


    // submit handler
    const submitHireForm = async(e) => {
            e.preventDefault();

            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


            if(dicussIdeal == "" ||  services == "" ||   startproject == "" ||  budget == "" ||  fullName == "" ||  email == "" ||  besWayToReach == "" ||  besWayToReachDetails == "" )
            // if(dicussIdeal == "" )
            {

                Swal.fire({
                    title: "Error!",
                    text: "Provide all necessary details.",
                    icon: "error",
                   
                    color: "#fff",
                    background: "#010c4c",
                  
                    allowOutsideClick: false,
                    showDenyButton:false,
                   
                    confirmButtonText: "ok!",
                    confirmButtonColor: "#020f60",
                    
                  });

                  return false;


            }else if(services == "select"){

                Swal.fire({
                    title: "Error!",
                    text: "Select a service you are interested in",
                    icon: "error",
                   
                    color: "#fff",
                    background: "#010c4c",
                  
                    allowOutsideClick: false,
                    showDenyButton:false,
                   
                    confirmButtonText: "ok!",
                    confirmButtonColor: "#020f60",
                    
                  });

                  return false;


            }else if(startproject == "select"){

                Swal.fire({
                    title: "Error!",
                    text: "Select how soon you want to start the project.",
                    icon: "error",
                  
                    color: "#fff",
                    background: "#010c4c",
                  
                    allowOutsideClick: false,
                    showDenyButton:false,
                   
                    confirmButtonText: "ok!",
                    confirmButtonColor: "#020f60",
                    
                  });

                  return false;

            }else if(besWayToReach == "select"){

                Swal.fire({
                    title: "Error!",
                    text: "Select the best way to reach you.",
                    icon: "error",
                    
                    color: "#fff",
                    background: "#010c4c",
                  
                    allowOutsideClick: false,
                    showDenyButton:false,
                   
                    confirmButtonText: "ok!",
                    confirmButtonColor: "#020f60",
                    
                  });

                  return false;


            }else if(!email.match(validRegex)){

                Swal.fire({
                    title: "Error!",
                    text: "Invalid email",
                    icon: "error",
                  
                    color: "#fff",
                    background: "#010c4c",
                  
                    allowOutsideClick: false,
                    showDenyButton:false,
                   
                    confirmButtonText: "ok!",
                    confirmButtonColor: "#020f60",
                    
                  });

                  return false;


            }else if(sample == ""){

                setsample("null");

            }else{

                // connect to server
                $("#ShowCase2formButton1").show();
                $("#ShowCase2formButton").hide();

                let urls = "https://humblewebadmin.humblewebtech.com/api/hireUsFromMainWebsite";
                // let urls = "http://127.0.0.1:8000/api/hirepost";

                async function sendData()
                {
                    try{

                        const feedbackResp = await axios.post(urls, {
                            dicussIdeal: dicussIdeal,
                            services: services,
                            sample: sample,
                            startproject: startproject,
                            budget: budget,
                            fullName: fullName,
                            email: email,
                            besWayToReach: besWayToReach,
                            besWayToReachDetails: besWayToReachDetails,
                        });


                        // save returned respons
                        let statusMsgFromServer = feedbackResp.data.msg;
                        let statusCodeFromServer = feedbackResp.data.status;

                    

                        if(statusCodeFromServer == 200)
                        {

                           

                            Swal.fire({
                                title: "Success!",
                                text: statusMsgFromServer,
                                icon: "success",
                                // toast:true,
                                color: "#fff",
                                background: "#010c4c",
                                // timerProgressBar: true,
                                allowOutsideClick: false,
                                showDenyButton:false,
                                // showConfirmButton: false,
                                confirmButtonText: "ok!",
                                confirmButtonColor: "#020f60",
                                
                              });

                              $("#ShowCase2formButton1").hide();
                              $("#ShowCase2formButton").show();



                        }else if(statusCodeFromServer == 501){

                            Swal.fire({
                                title: "Error!",
                                text: statusMsgFromServer,
                                icon: "error",
                                // toast:true,
                                color: "#fff",
                                background: "#010c4c",
                                // timerProgressBar: true,
                                allowOutsideClick: false,
                                showDenyButton:false,
                                // showConfirmButton: false,
                                confirmButtonText: "ok!",
                                confirmButtonColor: "#020f60",
                                
                              });

                              return false;

                        }else{

                               

                        }


                    }catch (err){

                        Swal.fire({
                            title: "Error!",
                            text: "Error connecting to server!",
                            icon: "error",
                            // toast:true,
                            color: "#fff",
                            background: "#010c4c",
                            // timerProgressBar: true,
                            allowOutsideClick: false,
                            showDenyButton:false,
                            // showConfirmButton: false,
                            confirmButtonText: "ok!",
                            confirmButtonColor: "#020f60",
                            
                          });

                          $("#ShowCase2formButton1").hide();
                          $("#ShowCase2formButton").show();

                          console.log(err);

                    }




                }

                sendData();





            }




        
        
    }


    return (
        <>
                <Head />
        
        
        <div className='hireMainFormContainer'>
{/* <section className='sectionFour'>
    <div className='sectionFour_Content'> */}
                <h2>Web</h2>
       

            

            {/* hire us form */}

            <div className='hireUsFormContainer'>
                    <h2>Hire Us</h2>

                    <div className='hireUsFormContainer_Intro'>
                        <h3>HERE’S WHAT TO EXPECT:</h3>
                        <ul>
                            <li> An immediate email confirming your request. </li>
                            <li> A phone call from one of the strategists at Humble Web Tech. </li>
                            <li> We will work together to clarify your requirements. </li>
                            <li> We will share ideas on how to help you achieve your goals. </li>
                            <li> Whenever you’re ready to begin, we’ll send you a custom quote or proposal. </li>
                        </ul>
                    </div>
                    
                    <br />
                    <br />

                    <div className='hireUsFormContainer_subForm'>
                    <form action="" >


                                <label htmlFor="fullname" className='ShowCase2formLabel'>Tell us about your business and goals. <span style={{color:"red"}}>*</span></label> <br />
                                <textarea value={dicussIdeal} onChange={(e) => setdicussIdeal(e.target.value)} cols="40" rows="5" placeholder='Enter Details' className='ShowCase2formTextArea' style={{resize: "none",}}></textarea>
                                
                                
                                <br />
                                <br />
                                <label htmlFor="fullname" className='ShowCase2formLabel'>What services are you interested in? <span style={{color:"red"}}>*</span></label> <br />
                                <select value={services} onChange={(e) => setservices(e.target.value)} className='ShowCase2formInput' style={{height:40}}>
                                    <option value="select">-- Select --</option>
                                    <option value="Webiste">Webiste</option>
                                    <option value="Mobile Application (IOS and Andriod)">Mobile Application (IOS and Andriod)</option>
                                    <option value="Both Website and Mobile App">Both Website and Mobile App</option>

                                </select>

                                <br />
                                <br />
                                <label htmlFor="fullname" className='ShowCase2formLabel'>Do you have a sample of what you want?  (optional)</label> <br />
                                <input type="text" value={sample} onChange={(e) => setsample(e.target.value)} className='ShowCase2formInput' placeholder='Enter sample link or name of the application or website.' />
                                

                                <br />
                                <br />
                                <label htmlFor="fullname" className='ShowCase2formLabel'>When would you like to begin the project? <span style={{color:"red"}}>*</span></label> <br />
                                <select value={startproject} onChange={(e) => setstartproject(e.target.value)} className='ShowCase2formInput' style={{height:40}}>
                                    <option value="select">-- Select --</option>
                                    <option value="Immediately">Immediately</option>
                                    <option value="1-2 Weeks">1-2 Weeks</option>
                                    <option value="In a couple of months">In a couple of months</option>
                                    <option value="Not sure yet">Not sure yet</option>
                                    
                                </select>

                                <br />
                                <br />
                                <label htmlFor="fullname" className='ShowCase2formLabel'>What's your budget (USD)? <span style={{color:"red"}}>*</span></label> <br />
                                <input type="text" value={budget} onChange={(e) => setbudget(e.target.value)} className='ShowCase2formInput' placeholder='Enter Budget (USD)' />
                                

                                <br />
                                <br />
                                <label htmlFor="fullname" className='ShowCase2formLabel'>Full Name <span style={{color:"red"}}>*</span></label> <br />
                                <input type="text" value={fullName} onChange={(e) => setfullName(e.target.value)}  className='ShowCase2formInput' placeholder='Enter Full Name' />
                                

                                <br />
                                <br />
                                <label htmlFor="fullname" className='ShowCase2formLabel'>Email <span style={{color:"red"}}>*</span></label> <br />
                                <input type="email" value={email} onChange={(e) => setemail(e.target.value)} className='ShowCase2formInput' placeholder='Enter Email' />
                                

                            
                                <br />
                                <br />
                                <label htmlFor="fullname" className='ShowCase2formLabel'>Best way to reach you <span style={{color:"red"}}>*</span></label> <br />
                                <select id='besWayToReach' value={besWayToReach} onChange={updateBestWayDetails} className='ShowCase2formInput' style={{height:40}}>
                                    <option value="select">-- Select --</option>
                                    <option value="Whatsapp" >Whatsapp</option>
                                    <option value="Email">Email</option>
                                    <option value="Telegram">Telegram</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Linkedln">Linkedln</option>
                                       
                                </select>

                                <br />
                                <br />


                                <div className='ShowCase2formLabelContainerx' id='ShowCase2formLabelContainerx'>
                                <label htmlFor="fullname" className='ShowCase2formLabel' id='chnx'>    </label> <br />
                                <input type="text" value={besWayToReachDetails} onChange={(e) => setbesWayToReachDetails(e.target.value)}  className='ShowCase2formInput' placeholder='Enter Details' />
                                
                                <br />
                                <br />
                                </div>
                                
                                

                            
                                
                                <button className='ShowCase2formButton' id='ShowCase2formButton' style={{backgroundColor: "#fff", color: "#020f60"}} onClick={submitHireForm}>SEND</button>
                                <button className='ShowCase2formButton1' style={{backgroundColor: "red"}} id='ShowCase2formButton1' disabled>Processing...</button>

                            </form>


                    </div>

            </div>
    {/* </div>


</section> */}
</div>
        
        
        
        
                <Foot />
        </>


    );

}