
export default function About(){
    return (
        <>
              <center>
              <h2>Page Not Found</h2>
              </center>
              
        </>


    );

}