import {Link } from "react-router-dom";


import Head from '../pages/Head';
import Foot from '../pages/Foot';
import bg4 from "../cert.jpg";

export default function About(){



    return (
        <>
                <Head />


                <section className='sectioneOne'>

                <br />
                <br />
                <br />
                <br />
    
                
            <div className='sectioneOneContainer'>
                <div className='subSectioneOneContainer'>
                <br />
                <br />
                    <h2 className='subSectioneOneContainer_Heading'>About Us</h2>
                   
                    <p>
                    Humble Web Tech is a reputable and one of the top web design and mobile app development company. We provide professional services on website design and mobile app development.

                    </p>

                    <p>
                    We don’t just build basic websites, your site is expertly designed and marketed by professionals who are wizards when it comes to turning users into raving fanatics and generating consistent leads for your business.


                    </p>
                   
                    <p>
                    We are one of the biggest and fastest growing web design and digital maketing agency with over four years of working experience. We specialise in building businesses and brands digitally across the world.


                    </p>

                    <p>
                    Finding a web designer who specialises in everything you need them to can be very difficult, and that is where we come in. We offer the best services.


                    </p>

                    <p>
                    Our services and products are backed by latest technology, years of cumulative experience. Our ultimate objective is to provide IT services and solutions that helps in simpifying and growing our client's business.


                    </p>

                    <p>
                    Our deep understanding of  Web design and development, mobile app development and SEO, which is reflected in every website we build is one of the reasons why we stand out. We invest time and money continuously developing our skills in these areas, which means your website is the best it can possibly be.


                    </p>

                    <br />
                    <center>
                       <img src={bg4} alt="HumbleWeb Tech Logo" style={{width:"60%"}}/>
 
                    </center>

                   <br />
                   {/* <Link to="/hire" className='hireUsButton'>Hire Us</Link> */}


                   <br />
                <br />
                <br />
                </div>

            </div>


            
        </section>





<section className='visionPageSection'>

        <div className='visionPageSection_Container'>
            <div className='visionPageSection_Container_Intro'>
                <h2>Humble Web Tech</h2>
                <p>
                    Our deep understanding of Web design and development, mobile app development and SEO, which is reflected in every website we build is one of the reasons why we stand out. We invest time and money continuously developing our skills in these areas, which means your website is the best it can possibly be.

                </p>
            </div>

            <div className='visionPageSection_Container_Card'>

                <div className='visionPageSection_Container_Card_Content'>
                    <h2>Vision</h2>
                    <p>
                    Our vision is to be a trusted business partner for our clients and ensure positive results.

                    </p>
                </div>
                <div className='visionPageSection_Container_Card_Content'>
                    <h2>Mission</h2>
                    <p>
                        Our mission here is to make sure our clients are very happy and satisfied with our services and also add values to their business or brand through our tech services.

                    </p>
                </div>

                <p className="clearFloat"></p>
            </div>
            


            
        </div>

</section>















        
        
        
        
        
        
                <Foot />
        </>


    );

}