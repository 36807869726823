
import {useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import $ from 'jquery';

import Head from '../pages/Head';
import Foot from '../pages/Foot';


export default function Contact(){
    const navigate = useNavigate();

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [messageFromUser, setMessageFromUser] = useState("");

    const [errMsg, setErrMsg] = useState("");




    const submitContactForm = (e) => {
        e.preventDefault();

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if(fullName == "" || email == "" || messageFromUser == "" ){

            // setErrMsg("Provide all details");

            Swal.fire({
                title: "Error!",
                text: "Provide all necessary details.",
                icon: "error",
                // toast:true,
                color: "#fff",
                background: "#010c4c",
                // timerProgressBar: true,
                allowOutsideClick: false,
                showDenyButton:false,
                // showConfirmButton: false,
                confirmButtonText: "ok!",
                confirmButtonColor: "#020f60",
                
              });

              return false;


        }else if(!email.match(validRegex)){

            // setErrMsg("Provide a valid email address");
            Swal.fire({
                title: "Error!",
                text: "Provide a valid email address.",
                icon: "error",
                // toast:true,
                color: "#fff",
                background: "#010c4c",
                // timerProgressBar: true,
                allowOutsideClick: false,
                showDenyButton:false,
                // showConfirmButton: false,
                confirmButtonText: "ok!",
                confirmButtonColor: "#020f60",
                
              });
            return false;


        }else{

            setErrMsg("");
            $("#ShowCase2formButton1").show();
            $("#ShowCase2formButton").hide();

            let urls = "https://humblewebadmin.humblewebtech.com/api/contactFormFromMainWebsite";
            // let urls = "http://127.0.0.1:8000/api/hirepost";

            async function runProcess() {
            try {
                
                const res =   await axios.post(urls, {
                    fullName: fullName,
                    email: email,
                    messageFromUser: messageFromUser,
                  });
            
                //   console.log(res.data.msg);

                let statusMsgFromServer = res.data.msg;
                let statusCodeFromServer = res.data.status;

                if(statusCodeFromServer == 200)
                {
                    // alert(statusMsgFromServer);

                    Swal.fire({
                        title: "Sent",
                        text: statusMsgFromServer,
                        icon: "success",
                        // toast:true,
                        color: "#fff",
                        background: "#010c4c",
                        // timerProgressBar: true,
                        allowOutsideClick: false,
                        showDenyButton:false,
                        // showConfirmButton: false,
                        confirmButtonText: "ok!",
                        confirmButtonColor: "#020f60",
                        
                      });


                      $("#ShowCase2formButton1").hide();
                      $("#ShowCase2formButton").show();
                    
                      setFullName("");
                      setEmail("");
                      setMessageFromUser("");


                    // navigate("/");
                }else if(statusCodeFromServer == 501){

                    alert(statusMsgFromServer);

                }else{


                }



              
            } catch (error) {

                setErrMsg("Error sending mail");
                // console.log(error);
            }

          }
          
          runProcess();







        }




        


        // async function runProcess() {
        //     const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
        //     const json = await response.json();
        //     console.log(json)
        //   }
          
        //   runProcess();
        


        // async function runProcess() {
        //     try {
        //       const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
        //       const json = await response.json();
        //       console.log(json);
        //     } catch (error) {
        //       console.log("error trying to fetch data");
        //     }
        //   }
          
        //   runProcess();



    }








    return (
        <>
                <Head />
        
        
                    <div className='contactPageForMobile'>
                <div className='showcaseContainer'>
            <div className='subContainer'>

                {/* subContainerShowCase1 */}
                <div className='subContainerShowCase1'>
                    <div className='subContainerShowCase1_Content'>
                        <h2 className='showCaseHeading'>Contact Us</h2>
                               
                        <div className='contactPageFormDetails'>
                            <div className='mobilecontactPageFormDetails'>
                           
                                <h4>Address: </h4>
                                <p>Wing B, suite B8, First Floor, 2XL Mall, 3rd Avenue, Christiana Ajayi Okunaga, Gwarinpa Estate 900108, Federal Capital Territory</p>
                                
                                <h4>Email: </h4>
                                <p href="mailto:support@humblewebtech.com" style={{color: '#020f60', textDecoration: "none",}}>support@humblewebtech.com</p>
                                <p href="mailto:humblewebtech@gmail.com" style={{color: '#020f60', textDecoration: "none",}}>humblewebtech@gmail.com</p>

                                <h4>Phone / WhatsApp:</h4>
                                <p href="tel:+2349054020833" style={{color: '#020f60', textDecoration: "none",}}>+2349054020833</p>

                                {/* <h4>Social Media:</h4>
                                <ul>
                                    <li>Facebook: humblewebtech</li>
                        
                                </ul> */}
                            </div>
                        </div>
                        
            

                    </div>

                    
                </div>

                {/* subContainerShowCase2 */}
                <div className='subContainerShowCase2'>
                    <div className='subContainerShowCase2_Content'>
                        <h2 className='subContainerShowCase2_Content_Heading'>Contact Us</h2>

                                    <p style={{fontSize:13, color: "red", textAlign: "center"}}> {errMsg} </p>

                        <div className='subContainerShowCase2_Content_Form'>
                            <form action="" >
                                {/* <label htmlFor="fullname" className='ShowCase2formLabel'>Full Name</label> <br /> */}
                                <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} name='fullname'  className='ShowCase2formInput' placeholder='Enter Your Full Name' />
                                
                                <br />
                                <br />
                                
                                {/* <label htmlFor="fullname" className='ShowCase2formLabel'>Email</label> <br /> */}
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name='email'  className='ShowCase2formInput' placeholder='Enter Your Email' autoCapitalize='none' autoComplete='none' />

                                <br />
                                <br />

                                <textarea  value={messageFromUser} onChange={(e) => setMessageFromUser(e.target.value)}  name="messageFromUser" id="" cols="40" rows="5" placeholder='Enter Message' className='ShowCase2formTextArea' style={{resize: "none",}}></textarea>
                                <br />
                                <br />

                                <button className='ShowCase2formButton' id='ShowCase2formButton' onClick={submitContactForm}>Send</button>
                                <button className='ShowCase2formButton1' id='ShowCase2formButton1' disabled>Processing...</button>

  
                            </form>
                            
                        </div>

                    </div>

                </div>




                {/* mobile view */}
                <div className='mobilesubContainerShowCase2'>
                    <div className='subContainerShowCase2_Content'>
                        <h2 className='subContainerShowCase2_Content_Heading'>Contact Us</h2>
                        <p style={{fontSize:13, color: "red", textAlign: "center"}}> {errMsg} </p>
                        <div className='subContainerShowCase2_Content_Form'>
                        <form action="" >
                                {/* <label htmlFor="fullname" className='ShowCase2formLabel'>Full Name</label> <br /> */}
                                <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} name='fullname'  className='ShowCase2formInput' placeholder='Enter Your Full Name' />
                                
                                <br />
                                <br />
                                
                                {/* <label htmlFor="fullname" className='ShowCase2formLabel'>Email</label> <br /> */}
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name='email'  className='ShowCase2formInput' placeholder='Enter Your Email' autoCapitalize='none' autoComplete='none' />

                                <br />
                                <br />

                                <textarea  value={messageFromUser} onChange={(e) => setMessageFromUser(e.target.value)}  name="messageFromUser" id="" cols="40" rows="5" placeholder='Enter Message' className='ShowCase2formTextArea' style={{resize: "none",}}></textarea>
                                <br />
                                <br />

                                <button className='ShowCase2formButton' id='ShowCase2formButton' onClick={submitContactForm}>Send</button>
                                <button className='ShowCase2formButton1' id='ShowCase2formButton1' disabled>Processing...</button>

  
                            </form>
                            
                            
                        </div>

                    </div>

                </div>


                <p className="clearFloat"></p>
            </div>
        
        </div>

        </div>
        
                <Foot />
        </>


    );

}