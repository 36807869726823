import Head from '../pages/Head';
import Foot from '../pages/Foot';


export default function Services(){
    return (
        <>
                <Head />
        

        <br />
        <br />
        <br />
        
        
                <section className='sectionTwo'>
                <div className='subsectionTwo'>
                    <h2>Our Services</h2>

                    <div className='ourSericesContent'>
                        {/* card start */}
                        <div className='ourSericesContent_Card'>

                            <div className='ourSericesContent_Card_Content'>
                                <div className='ourSericesContent_Card_Content_Images_1'></div>

                                <h3>Web Design & Development</h3>

                                <p>
                                A website is the heart of your business. It’s that little space on the internet that represents who you are and what you stand for. We design standard and responsive websites.

                                </p>
                            </div>

                        </div>

                         {/* card start */}
                         <div className='ourSericesContent_Card'>

                            <div className='ourSericesContent_Card_Content'>
                                <div className='ourSericesContent_Card_Content_Images_3'></div>

                                <h3>Mobile App Development</h3>

                                <p>
                                        At Humble Web Tech we develop mobile applications for both android and IOS. Our goal is to make sure that users are adopting and benefiting from all its features.

                                </p>
                            </div>


                        </div>


                        {/* card start */}
                        <div className='ourSericesContent_Card'>

                            <div className='ourSericesContent_Card_Content'>
                                <div className='ourSericesContent_Card_Content_Images_2'></div>

                                <h3>SEO & Digital Marketing</h3>

                                <p>
                                Our mission is to grow your online reputation, drive quality organic traffic to your website, generating and increasing your sales & build awareness for your brand.

                                </p>
                            </div>

                        </div>

                       

                        {/* card start */}
                        <div className='ourSericesContent_Card'>

                            <div className='ourSericesContent_Card_Content'>
                                <div className='ourSericesContent_Card_Content_Images_4'></div>

                                <h3>Content Marketing</h3>

                                <p>
                                We drive profitable customer with our strategic marketing approach focused on creating and distributing your content to attract, acquire, and engage a defined target audience.

                                </p>
                            </div>


                        </div>


                        <p className="clearFloat"></p>
                    </div>


                </div>

        </section>

        
        
        
        
                <Foot />
        </>


    );

}